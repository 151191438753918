@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Handwritten'; 
  src: url('./fonts/Virgil.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}
